<template>
	<div
		data-component="dds-defender-switch-field"
	>
		<label
			data-element="label"
		>
			DDS should play the cards from the following suits after the first win (in order):
		</label>
		<div v-for="position in positions" :key="position.short" data-element="suit-select">
			<span data-element="position-name">
				{{ position.name }}
			</span>
			<select-field
				htmlOptions
				hideSelected
				multiple
				name="suit"
				:hasPlaceholder="false"
				:hasLabel="false"
				:selectLabel="''"
				:deselectLabel="''"
				:showNoOptions="false"
				:options="getSuitOptions"
				:value="getValue(position)"
				@input="handleSuitSelection($event, position)"
			/>
		</div>
	</div>
</template>

<script>

	import SelectField from '@/components/forms/SelectField';
	import { suitOptions } from '@/consts';
	import { createSuitElement } from '@/helpers';

	export default {
		components: {
			SelectField
		},
		props: {
			deal: {
				type: Object,
				default: undefined
			},
			positions: {
				type: Array,
				required: true,
				default () {
					return [];
				}
			}
		},
		data: () => ({
		}),
		computed: {
			getSuitOptions () {
				return suitOptions.map(option => {
					return {
						value: option.value,
						text: createSuitElement(option.symbol, option.colour)
					};
				});
			}
		},
		methods: {
			getValue (position) {
				const valueKey = `ddsPlaysSuitsAfterFirstWin${position.name}`;

				return this.deal[valueKey] || [];
			},
			handleSuitSelection (value, position) {
				const valueKey = `ddsPlaysSuitsAfterFirstWin${position.name}`;

				this.$set(this.deal, valueKey, value);
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component="dds-defender-switch-field"] {

		[data-element="suit-select"] {
			display: flex;
			align-items: center;
			cursor: pointer;
			margin: rem(18) 0;

			&:not(:last-child) {
				margin-right: rem(20);
			}

			[data-component='select-field'] {
				min-width: 120px;
				margin: 0;
			}
		}

		[data-element="position-name"] {
			display: flex;
			align-items: center;
			font-size: rem(18);
			line-height: 1;
			margin-bottom: 0;
			margin-right: rem(18);
			font-weight: 700;
		}
	}

</style>
