<template>
	<div
		data-component="dds-discard-first-field"
	>
		<label
			data-element="label"
		>
			DDS should discard the lowest card first:
		</label>
		<label v-for="position in positions" :key="position.short" data-element="discards-checkbox">
			<span data-element="position-name">
				{{ position.name }}
			</span>
			<checkbox-field
				:show-label="false"
				:name="position.short"
				:id="'discards-' + position.short"
				:checked="getIsChecked(position)"
				@change="onChange($event, position)"
			/>
		</label>
	</div>
</template>

<script>

	import CheckboxField from '@/components/forms/CheckboxField';

	export default {
		components: {
			CheckboxField
		},
		props: {
			deal: {
				type: Object,
				default: undefined
			},
			positions: {
				type: Array,
				required: true,
				default () {
					return [];
				}
			}
		},
		data: () => ({
		}),
		computed: {
		},
		methods: {
			getIsChecked (position) {
				const valueKey = `ddsDiscardsLowestCards${position.name}`;

				return this.deal[valueKey];
			},
			onChange (checked, position) {
				const valueKey = `ddsDiscardsLowestCards${position.name}`;

				this.$set(this.deal, valueKey, checked);
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component="dds-discard-first-field"] {

		[data-element="discards-checkbox"] {
			display: flex;
			cursor: pointer;

			::v-deep [data-component="checkbox-field"] {
				margin: rem(10) 0;

				input {
					margin: auto rem(10) auto 0;
					cursor: pointer;
				}
			}

			[data-element="position-name"] {
				display: flex;
				align-items: center;
				font-size: rem(18);
				line-height: 1;
				margin-bottom: 0;
				margin-right: rem(10);
				font-weight: 700;
			}
		}
	}

</style>
