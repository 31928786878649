<template>
	<div
		data-component="dds-prioritize-suits-field"
	>
		<label
			data-element="label"
		>
			DDS should prioritize the lowest cards in:
		</label>
		<div v-for="position in positions" :key="position.short" data-element="suit-checkboxes">
			<span data-element="position-name">
				{{ position.name }}
			</span>
			<label data-element="suit-checkbox" v-for="suit in suitOptions" :key="suit.value">
				<checkbox-field
					:show-label="false"
					:name="suit.value"
					:id="suit.value"
					:checked="getIsChecked(suit.value, position)"
					@change="onChange($event, suit.value, position)"
				/>
				<suit-symbol
					:suitName="suit.value"
					:withoutPadding="true"
				/>
			</label>
		</div>
	</div>
</template>

<script>

	import CheckboxField from '@/components/forms/CheckboxField';
	import SuitSymbol from '@/components/diagrams/SuitSymbol';

	export default {
		components: {
			CheckboxField,
			SuitSymbol
		},
		props: {
			deal: {
				type: Object,
				default: undefined
			},
			positions: {
				type: Array,
				required: true,
				default () {
					return [];
				}
			}
		},
		data: () => ({
			suitOptions: [
				{
					value: 'C',
					symbol: '♣',
					colour: 'black'
				},
				{
					value: 'D',
					symbol: '♦',
					colour: 'red'
				},
				{
					value: 'H',
					symbol: '♥',
					colour: 'red'
				},
				{
					value: 'S',
					symbol: '♠',
					colour: 'black'
				}
			]
		}),
		computed: {
		},
		methods: {
			getIsChecked (suit, position) {
				const valueKey = `ddsPrioritizesLowestCardsForSuits${position.name}`;

				if (this.deal[valueKey]) {
					return this.deal[valueKey].includes(suit);
				}

				return false;
			},
			onChange (checked, suit, position) {
				const valueKey = `ddsPrioritizesLowestCardsForSuits${position.name}`;
				const currentValue = this.deal[valueKey] || [];

				if (checked) {
					this.$set(this.deal, valueKey, [...currentValue, suit]);
				} else {
					this.$set(this.deal, valueKey, currentValue.filter(item => item !== suit));
				}
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component="dds-prioritize-suits-field"] {

		[data-element="suit-checkboxes"] {
			display: flex;

			[data-element="suit-checkbox"] {
				display: flex;
				align-items: center;
				cursor: pointer;

				&:not(:last-child) {
					margin-right: rem(20);
				}

				::v-deep [data-component="checkbox-field"] {
					input {
						margin: auto rem(10) auto 0;
						cursor: pointer;
					}
				}

				::v-deep [data-component="suit-symbol"] {
					margin: 0 rem(1);

					[data-component="icon"] {
						margin-top: 0;
						width: rem(24);
						height: rem(24);
					}
				}
			}

			[data-element="position-name"] {
				display: flex;
				align-items: center;
				font-size: rem(18);
				line-height: 1;
				margin-bottom: 0;
				margin-right: rem(18);
				font-weight: 700;
			}
		}
	}

</style>
