import Vue                    from 'vue';
import Router                 from 'vue-router';
import routes                 from 'vue-auto-routing';
import store                  from '../store';
import { createRouterLayout } from 'vue-router-layout';

Vue.use(Router);

const RouterLayout = createRouterLayout(layout => {
	console.log(layout);
	return import('@/layouts/' + layout + '.vue');
});

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			component: RouterLayout,
			children: routes
		},
		{
			path: '*',
			component: RouterLayout,
			children: [{
				name: 'page-404',
				path: '',
				component: () => import('@/pages/404')
			}]
		}
	]
});

router.onError(error => {
	if (/loading chunk \d* failed./i.test(error.message)) {
		window.location.reload();
	}
});

async function awaitInit () {
	return new Promise((resolve, reject) => {

		const interval = setInterval(() => {
			if (store.getters['auth/getInit']) {
				clearInterval(interval);
				resolve();
			}
		}, 300);
	});
}

router.beforeEach(async (to, from, next) => {
	if (window.location.protocol === 'http:' && window.location.hostname !== 'localhost') {
		window.location.href = `https://${window.location.hostname}${window.location.pathname}${window.location.search}`;
	}

	if (!store.getters['auth/getInit']) {
		await awaitInit();
	}

	// clear ui
	store.commit('ui/setDrawerIsHidden');
	store.commit('lesson/setLessonNavIsHidden');
	store.commit('ui/setProfileMenuIsHidden');
	store.commit('ui/removeNotification');
	store.commit('ui/hideError');
	store.commit('ui/clearTourSteps');
	if (to.fullPath !== '/') {
		store.commit('ui/setLoadingIsVisible');
	}
	// clear deal library
	if (!to.fullPath.includes('/activities')) {
		store.commit('lesson/clearContext');
		store.commit('lesson/clearCourseId');
		store.commit('lesson/clearLesson');
		store.commit('lesson/clearActivity');
	}
	if (!to.fullPath.includes('/teaching/deals') &&
			to.fullPath !== '/simulator-remote') {
		store.commit('dealLibrary/clearGroups');
		store.commit('dealLibrary/clearActiveFilters');
		store.commit('dealLibrary/clearFilterOptions');
		store.commit('dealLibrary/clearSearchString');
		store.commit('dealLibrary/clearCurrentPage');
		store.commit('dealLibrary/clearNumPages');
		store.commit('dealLibrary/clearTotalItems');
	}
	// clear quiz
	store.dispatch('quiz/clearQuiz');
	// clear deal run through
	store.commit('dealLibrary/clearActivity');
	// clear simulator
	if (to.fullPath !== '/simulator-remote') {
		// clear simulator
		store.dispatch('simulator/clearSimulator');
		store.dispatch('simulatorControls/clearSimulatorControls');
		store.commit('ui/setModalIsHidden');
	}

	const isPublic = to.matched.some((component) => {
		return component?.meta?.isPublic === true;
	});
	const isPublicOnly = to.matched.some((component) => {
		return component?.meta?.isPublicOnly === true;
	});
	const isTeacherOnly = to.matched.some((component) => {
		return component?.meta?.isTeacherOnly === true;
	});
	const isAdminOnly = to.matched.some((component) => {
		return component?.meta?.isAdminOnly === true;
	});
	const isAuthenticated = store.getters['auth/getIsAuthenticated'];
	const isTeacher = store.getters['auth/getIsTeacher'];
	const isAdmin = store.getters['auth/getIsAdmin'];
	if (isPublicOnly && isAuthenticated) {
		next('/my-courses');
		return;
	}
	if (isPublicOnly && !isAuthenticated) {
		next();
		return;
	}
	if (!isPublic && !isAuthenticated) {
		next('/login');
		return;
	}
	if (isTeacherOnly && !isTeacher) {
		store.commit('ui/showError');
		next();
		return;
	}
	if (isAdminOnly && !isAdmin) {
		store.commit('ui/showError');
		return;
	}
	next();
});

router.afterEach((to, from) => {
	if (Vue.prototype.$posthog) {
		Vue.nextTick(() => {
			Vue.prototype.$posthog.capture('$pageview', {
				$current_url: to.fullPath
			});
		});
	}

	if (window.fbq) {
		window.fbq('track', 'PageView');
	}
});

export default router;
